import React, { useState, useEffect, useContext, useRef } from "react";
import { useIntl } from 'react-intl';

const AllCategories = () => {
  const intl = useIntl();


  return (
    <div>
      <div className="container-fluid leases-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h1 className="fw800 cwhite mb-4">Rentals made easy!</h1>
              <a className="btn btn-orang-def">Rent Now</a>
            </div>
          </div>
        </div>
      </div>


      <div className="container main-wrap product-list-div">
        <div className="row">
          <div className="col-12">
            
            <div className="input-group mb-3 prod-search">
              
              <svg width="18" height="18" style={{position:"absolute", zIndex:"9", top:"14px", left:"14px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9 3.00115C7.4087 3.00115 5.88258 3.63329 4.75736 4.75851C3.63214 5.88373 3 7.40985 3 9.00115C3 10.5924 3.63214 12.1186 4.75736 13.2438C5.88258 14.369 7.4087 15.0012 9 15.0012C10.5913 15.0012 12.1174 14.369 13.2426 13.2438C14.3679 12.1186 15 10.5924 15 9.00115C15 7.40985 14.3679 5.88373 13.2426 4.75851C12.1174 3.63329 10.5913 3.00115 9 3.00115ZM1.70192e-07 9.00115C-0.000180585 7.58471 0.333964 6.18822 0.975257 4.92526C1.61655 3.66231 2.54688 2.56855 3.69059 1.73294C4.8343 0.897329 6.15909 0.343468 7.55722 0.116399C8.95534 -0.11067 10.3873 -0.00453374 11.7367 0.426175C13.0861 0.856883 14.3147 1.6 15.3227 2.59509C16.3308 3.59018 17.0897 4.80915 17.5378 6.15285C17.9859 7.49655 18.1105 8.92704 17.9015 10.328C17.6925 11.7289 17.1558 13.0607 16.335 14.2151L23.5605 21.4406C23.8337 21.7236 23.9849 22.1025 23.9815 22.4958C23.9781 22.889 23.8203 23.2653 23.5422 23.5434C23.2641 23.8215 22.8879 23.9792 22.4946 23.9827C22.1013 23.9861 21.7224 23.8349 21.4395 23.5617L14.2155 16.3377C12.869 17.2951 11.285 17.8634 9.63701 17.9803C7.98901 18.0973 6.34061 17.7583 4.87245 17.0006C3.40429 16.2429 2.17303 15.0957 1.31359 13.6847C0.45414 12.2737 -0.000321076 10.6533 1.70192e-07 9.00115Z" fill="#242424"/>
              </svg>

              <input type="text" className="form-control input-prodsearch" placeholder="Im looking for"/>
              <select className="form-select select-prodsearch">
                <option value="">Category</option>
                <option value="Clothes">Clothes</option>
                <option value="Accessories">Accessories</option>
              </select>
            </div>


            <h3 className="cblue fw700 mt-5 mb-5">Product Categories</h3>

            
            <div className="row">
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate1.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Accessories</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate2.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Apartments</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate3.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Bicycle</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate4.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Clothes</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate5.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Heavy Equipments</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate6.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Offices</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate7.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Parking Spaces</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate8.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Real Estates</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate9.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Retail Spaces</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate10.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Storage Spaces</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate11.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Transportations</a>
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-6 col-lg-3">
                <div className="all-cate-singgle-div">
                  <div className="allcate-img-div">
                    <img src="assets/images/cate12.png" className="imgenclose" />
                  </div>

                  <p className="cblack fw700 fs18 mb-0 mt-2">
                    <a href="#" className="cblack">Venues</a>
                  </p>
                </div>
              </div>




              <div className="col-12">
                <ul className="ul-pagination mt-5">
                  <li className="first">
                    <a href="">
                      
                    {intl.locale === "en" ? (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                      </svg>
                    ) : (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                      </svg>
                    )}

                    </a>
                  </li>
                  <li className="active">
                    <a href="">1</a>
                  </li>
                  <li className="">
                    <a href="">2</a>
                  </li>
                  <li className="">
                    <a href="">3</a>
                  </li>

                  <li className="last">
                    <a href="">
                      
                    {intl.locale === "en" ? (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                      </svg>
                    ) : (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                      </svg>
                    )}

                    </a>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default AllCategories;
