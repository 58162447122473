import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import {FormattedMessage} from 'react-intl';
import { RouteContext } from "../../../contexts/route.context";


const Notifications = () => {
  const { baseURL, apiHeaders, getNotificationSettings, notificationSettings, } = useContext(RouteContext);
  const [checkedAll, setCheckedAll] = useState(false);
  const [notiSound, setNotiSound] = useState(false);
  const [notiAppSound, setNotiAppSound] = useState(false);
  const [notiNewMes, setNotiNewMes] = useState(false);
  const [notiAppoint, setNotiAppoint] = useState(false);
  const [notiLoading, setNotiLoading] = useState(false);

  useEffect(() => {
    getNotificationSettings();
  }, []);

  useEffect(() => {
    if(notificationSettings) {
      setNotiLoading(true);
      let objNoti = Object.keys(notificationSettings).every((k) => notificationSettings[k]);
      setCheckedAll(objNoti);

      setNotiSound(notificationSettings.notification_sound);
      setNotiAppSound(notificationSettings.in_app_sound);
      setNotiNewMes(notificationSettings.new_message_notification);
      setNotiAppoint(notificationSettings.appoinment_notification);

      setTimeout(() => setNotiLoading(false), 200);
    }
  }, [notificationSettings]);


  const onChangeAll = async(e) => {
    setNotiLoading(true);
    let notiVal = e.target.checked;


    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
          mutation {
            notificationSetting(input: {notification_sound: ${notiVal}, in_app_sound: ${notiVal}, new_message_notification: ${notiVal}, appoinment_notification: ${notiVal}}){
              appoinment_notification
              in_app_sound
              new_message_notification
              notification
              notification_sound
            }
          }
        `
      }
    });

    console.log(result)

    if(result.data.data) {
      setCheckedAll(notiVal);
      setNotiSound(notiVal);
      setNotiAppSound(notiVal);
      setNotiNewMes(notiVal);
      setNotiAppoint(notiVal);

      setTimeout(() => setNotiLoading(false), 200);
    }
    else {

      Swal.fire({
        title: 'Update Notification Settings Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }


  const onChangeNotiSound = async(e) => {
    setNotiLoading(true);
    let notiVal = e.target.checked;


    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
          mutation {
            notificationSetting(input: {notification_sound: ${notiVal}}){
              appoinment_notification
              in_app_sound
              new_message_notification
              notification
              notification_sound
            }
          }
        `
      }
    });


    if(result.data.data) {
      setNotiSound(notiVal);

      setTimeout(() => setNotiLoading(false), 200);
    }
    else {

      Swal.fire({
        title: 'Update Notification Settings Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }


  const onChangeNotiAppSound = async(e) => {
    setNotiLoading(true);
    let notiVal = e.target.checked;


    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
          mutation {
            notificationSetting(input: {in_app_sound: ${notiVal}}){
              appoinment_notification
              in_app_sound
              new_message_notification
              notification
              notification_sound
            }
          }
        `
      }
    });


    if(result.data.data) {
      setNotiAppSound(notiVal);

      setTimeout(() => setNotiLoading(false), 200);
    }
    else {

      Swal.fire({
        title: 'Update Notification Settings Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }


  const onChangeNotiNewMes = async(e) => {
    setNotiLoading(true);
    let notiVal = e.target.checked;


    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
          mutation {
            notificationSetting(input: {new_message_notification: ${notiVal}}){
              appoinment_notification
              in_app_sound
              new_message_notification
              notification
              notification_sound
            }
          }
        `
      }
    });


    if(result.data.data) {
      setNotiNewMes(notiVal);

      setTimeout(() => setNotiLoading(false), 200);
    }
    else {

      Swal.fire({
        title: 'Update Notification Settings Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }


  const onChangeNotiAppoint = async(e) => {
    setNotiLoading(true);
    let notiVal = e.target.checked;


    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
          mutation {
            notificationSetting(input: {appoinment_notification: ${notiVal}}){
              appoinment_notification
              in_app_sound
              new_message_notification
              notification
              notification_sound
            }
          }
        `
      }
    });


    if(result.data.data) {
      setNotiAppoint(notiVal);

      setTimeout(() => setNotiLoading(false), 200);
    }
    else {

      Swal.fire({
        title: 'Update Notification Settings Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }
  

  return (
    <div>
      <div className="my-profile-div">
        <div className="row">
          <div className="col-12">
            <p className="fw700 cblue fs20 mb-2">
              <FormattedMessage 
                id="notisounds.text"
                defaultMessage="Notifications & Sounds"
                description="Notifications & Sounds"
              />
            </p>
            <p className="cblack fs14 mb-3">
              <FormattedMessage 
                id="notisoundsdesc.text"
                defaultMessage="Notifications & Sounds desc"
                description="Notifications & Sounds desc"
              />
            </p>

            <div className="div-2px mb-3" />

            {!notiLoading ? (
            <>
              <div className="notification-per-div border-bot pb-2 mb-3">
                <p className="mb-0 fw700 fs14 cblack">
                  <FormattedMessage 
                    id="on.text"
                    defaultMessage="On"
                    description="On"
                  />
                </p>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onChange={onChangeAll} defaultChecked={checkedAll}/>
                </div>
              </div>

              <div className="notification-per-div">
                <p className="mb-0 fs14 cblack">
                  <FormattedMessage 
                    id="notifisound.text"
                    defaultMessage="Notification sound"
                    description="Notification sound"
                  />
                </p>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onChange={onChangeNotiSound} defaultChecked={notiSound}/>
                </div>
              </div>

              <div className="notification-per-div">
                <p className="mb-0 fs14 cblack">
                  <FormattedMessage 
                    id="inapp.text"
                    defaultMessage="In-app sounds"
                    description="In-app sounds"
                  />
                </p>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onChange={onChangeNotiAppSound} defaultChecked={notiAppSound}/>
                </div>
              </div>

              <div className="notification-per-div">
                <p className="mb-0 fs14 cblack">
                  <FormattedMessage 
                    id="newmesnoti.text"
                    defaultMessage="New message notifications"
                    description="New message notifications"
                  />
                </p>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onChange={onChangeNotiNewMes} defaultChecked={notiNewMes}/>
                </div>
              </div>

              <div className="notification-per-div">
                <p className="mb-0 fs14 cblack">
                  <FormattedMessage 
                    id="appointnoti.text"
                    defaultMessage="Appointment notifications"
                    description="Appointment notifications"
                  />
                </p>
                <div className="form-check form-switch">
                  <input className="form-check-input" type="checkbox" onChange={onChangeNotiAppoint} defaultChecked={notiAppoint}/>
                </div>
              </div>  
            </>
            ) : (
              <div className="loader"></div>
            )}

            

          </div>
        </div>
      </div>
    </div>
  )
}

export default Notifications;
