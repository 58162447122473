import React, { useState, useEffect, useContext, useRef } from "react";
import { useIntl } from 'react-intl';

const LeasesSearch = () => {
  const intl = useIntl();


  return (
    <div>
      <div className="container-fluid leases-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h1 className="fw800 cwhite mb-4">Rentals made easy!</h1>
              <a className="btn btn-orang-def">Rent Now</a>
            </div>
          </div>
        </div>
      </div>


      <div className="container main-wrap product-list-div">
        <div className="row">
          <div className="col-12">
            
            <div className="input-group mb-3 prod-search">
              
              <svg width="18" height="18" style={{position:"absolute", zIndex:"9", top:"14px", left:"14px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fillRule="evenodd" clipRule="evenodd" d="M9 3.00115C7.4087 3.00115 5.88258 3.63329 4.75736 4.75851C3.63214 5.88373 3 7.40985 3 9.00115C3 10.5924 3.63214 12.1186 4.75736 13.2438C5.88258 14.369 7.4087 15.0012 9 15.0012C10.5913 15.0012 12.1174 14.369 13.2426 13.2438C14.3679 12.1186 15 10.5924 15 9.00115C15 7.40985 14.3679 5.88373 13.2426 4.75851C12.1174 3.63329 10.5913 3.00115 9 3.00115ZM1.70192e-07 9.00115C-0.000180585 7.58471 0.333964 6.18822 0.975257 4.92526C1.61655 3.66231 2.54688 2.56855 3.69059 1.73294C4.8343 0.897329 6.15909 0.343468 7.55722 0.116399C8.95534 -0.11067 10.3873 -0.00453374 11.7367 0.426175C13.0861 0.856883 14.3147 1.6 15.3227 2.59509C16.3308 3.59018 17.0897 4.80915 17.5378 6.15285C17.9859 7.49655 18.1105 8.92704 17.9015 10.328C17.6925 11.7289 17.1558 13.0607 16.335 14.2151L23.5605 21.4406C23.8337 21.7236 23.9849 22.1025 23.9815 22.4958C23.9781 22.889 23.8203 23.2653 23.5422 23.5434C23.2641 23.8215 22.8879 23.9792 22.4946 23.9827C22.1013 23.9861 21.7224 23.8349 21.4395 23.5617L14.2155 16.3377C12.869 17.2951 11.285 17.8634 9.63701 17.9803C7.98901 18.0973 6.34061 17.7583 4.87245 17.0006C3.40429 16.2429 2.17303 15.0957 1.31359 13.6847C0.45414 12.2737 -0.000321076 10.6533 1.70192e-07 9.00115Z" fill="#242424"/>
              </svg>

              <input type="text" className="form-control input-prodsearch" placeholder="Im looking for"/>
              <select className="form-select select-prodsearch">
                <option value="">Category</option>
                <option value="Clothes">Clothes</option>
                <option value="Accessories">Accessories</option>
              </select>
            </div>


            <p className="fw700 cgray mt-5 mb-5">
              Search Results: <span className="cblue ">Apartments</span>
            </p>

            
            <div className="row">
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod1.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod2.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod3.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>



              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod1.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod2.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod3.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod1.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod2.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-4">
                <div className="prod-list-single-div">
                  <div className="prod-img-div">
                    <img src="assets/images/prod3.png" className="imgenclose" />
                  </div>
                  <div className="prod-upper-text-div">
                    <p className="price fw800 cveryblack">SR 10,000 <span className="fw500">/year</span></p>
                    <div>
                      <p className="fw500 cblack mb-0 fs12">
                        <svg width="18" height="17" style={{marginRight:"8px", marginTop:"-4px"}} viewBox="0 0 20 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M9.0491 0.927078C9.3491 0.00607812 10.6521 0.00607812 10.9511 0.927078L12.4701 5.60108C12.5355 5.80157 12.6625 5.97626 12.8332 6.10018C13.0038 6.22411 13.2092 6.29092 13.4201 6.29108H18.3351C19.3041 6.29108 19.7061 7.53108 18.9231 8.10108L14.9471 10.9891C14.7763 11.1132 14.6492 11.2883 14.584 11.4891C14.5188 11.6899 14.5188 11.9063 14.5841 12.1071L16.1021 16.7811C16.4021 17.7031 15.3471 18.4691 14.5641 17.8991L10.5881 15.0111C10.4172 14.8869 10.2114 14.8199 10.0001 14.8199C9.78883 14.8199 9.58298 14.8869 9.4121 15.0111L5.4361 17.8991C4.6531 18.4691 3.5981 17.7021 3.8981 16.7811L5.4161 12.1071C5.48137 11.9063 5.48141 11.6899 5.4162 11.4891C5.351 11.2883 5.22389 11.1132 5.0531 10.9891L1.0771 8.10108C0.293095 7.53108 0.697095 6.29108 1.6651 6.29108H6.5791C6.79014 6.29113 6.9958 6.22442 7.16662 6.10048C7.33744 5.97654 7.46467 5.80173 7.5301 5.60108L9.0491 0.927078Z" fill="#FCE412"/>
                        </svg>
                        4.35
                      </p>
                      <p className="cgray fs12 mb-0">(10 reviews)</p>
                    </div>
                  </div>

                  <p className="cblack fs18 mb-2">
                    <a href="#" className="cblack">Product Title</a>
                  </p>
                  <p className="cgray fs14">
                    <svg width="18" height="21" style={{marginRight:"10px"}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
                    </svg>
                    Al-'Olayya, Riyadh
                  </p>
                </div>
              </div>


              <div className="col-12">
                <ul className="ul-pagination mt-5">
                  <li className="first">
                    <a href="">
                      
                    {intl.locale === "en" ? (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                      </svg>
                    ) : (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                      </svg>
                    )}

                    </a>
                  </li>
                  <li className="active">
                    <a href="">1</a>
                  </li>
                  <li className="">
                    <a href="">2</a>
                  </li>
                  <li className="">
                    <a href="">3</a>
                  </li>

                  <li className="last">
                    <a href="">
                      
                    {intl.locale === "en" ? (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M0.563313 9.23598C0.394589 9.06721 0.299805 8.83833 0.299805 8.59968C0.299805 8.36104 0.394589 8.13216 0.563313 7.96338L3.52701 4.99968L0.563313 2.03598C0.39937 1.86624 0.308655 1.6389 0.310706 1.40292C0.312756 1.16694 0.407408 0.941214 0.574276 0.774346C0.741143 0.607479 0.966875 0.512827 1.20285 0.510776C1.43883 0.508726 1.66617 0.599441 1.83591 0.763383L5.43591 4.36338C5.60464 4.53216 5.69942 4.76104 5.69942 4.99968C5.69942 5.23833 5.60464 5.46721 5.43591 5.63598L1.83591 9.23598C1.66714 9.40471 1.43826 9.49949 1.19961 9.49949C0.960965 9.49949 0.732088 9.40471 0.563313 9.23598Z" fill="#FF5833"/>
                      </svg>
                    ) : (
                      <svg width="6" height="10" viewBox="0 0 6 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5.43591 0.763508C5.60464 0.932283 5.69942 1.16116 5.69942 1.39981C5.69942 1.63846 5.60464 1.86733 5.43591 2.03611L2.47221 4.99981L5.43591 7.96351C5.59985 8.13325 5.69057 8.36059 5.68852 8.59657C5.68647 8.83255 5.59182 9.05828 5.42495 9.22514C5.25808 9.39201 5.03235 9.48666 4.79637 9.48871C4.5604 9.49076 4.33305 9.40005 4.16331 9.23611L0.563313 5.63611C0.394589 5.46733 0.299805 5.23846 0.299805 4.99981C0.299805 4.76116 0.394589 4.53228 0.563313 4.36351L4.16331 0.763508C4.33209 0.594784 4.56096 0.5 4.79961 0.5C5.03826 0.5 5.26714 0.594784 5.43591 0.763508Z" fill="#FF5833"/>
                      </svg>
                    )}

                    </a>
                  </li>
                </ul>
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  )
}

export default LeasesSearch;
