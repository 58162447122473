import React, { useState, useEffect, useContext, useRef } from "react";
import { Tab, Tabs, Dropdown } from 'react-bootstrap';
import { RouteContext } from "../contexts/route.context";
import {FormattedMessage, useIntl} from 'react-intl';


const Partner = () => {
  const intl = useIntl();
  const { getMe, me, } = useContext(RouteContext);

  useEffect(() => {
    getMe();
  }, []);

  return (
    <div>
      <div className="container-fluid leases-div">
        <div className="container main-wrap">
          <div className="row">
            <div className="col-12">
              <h1 className="fw800 cwhite mb-4">
                <FormattedMessage 
                  id="becomepartner.text"
                  defaultMessage="Become our partner!"
                  description="Become our partner!"
                />
              </h1>
              <a href="/step1-lessor" className="btn btn-orang-def">
                <FormattedMessage 
                  id="registernow.text"
                  defaultMessage="Register Now"
                  description="Register Now"
                />
              </a>
            </div>
          </div>
        </div>
      </div>


      <div className="container-fluid hiw-main-content">
        <div className="container main-wrap hiw-main-div">
          <div className="row">
            <div className="col-12 mb-5">
              <h3 className="fw700 cblack mb-0 text-center">
                <FormattedMessage 
                  id="howwork.text"
                  defaultMessage="How It Works"
                  description="How It Works"
                />
              </h3>
            </div>
          </div>

          <div className="row">
            <div className="col-12 col-lg-4">
              <div className="position-relative mb-5 mt-5 hiw-outer-div">
                <div className="d-flex justify-content-center">
                  <div className="hiw-per-top-div">
                    <p className="mb-0 fw700 cwhite fs30">1</p>
                  </div>
                </div>
                <div className="hiw-per-bot-div">
                  <p className="mb-3 fw700 cblack text-uppercase">{me && me.role.id == 3 ? (
                    <FormattedMessage 
                      id="lessorhw1.text"
                      defaultMessage="Step1"
                      description="Step1"
                    />
                  ) : (
                    <FormattedMessage 
                      id="lessehw1.text"
                      defaultMessage="Step1"
                      description="Step1"
                    />
                  )}</p>
                  {/* <p className="mb-0 fw500 cblack">Cras ut tortor vel quam finibus viverra. Praesent mattis mollis dui, quis faucibus magna scelerisque in. Morbi at erat aliquet, </p> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="position-relative mb-5 mt-5 hiw-outer-div">
                <div className="d-flex justify-content-center">
                  <div className="hiw-per-top-div">
                    <p className="mb-0 fw700 cwhite fs30">2</p>
                  </div>
                </div>
                <div className="hiw-per-bot-div">
                  <p className="mb-3 fw700 cblack text-uppercase">{me && me.role.id == 3 ? (
                    <FormattedMessage 
                      id="lessorhw2.text"
                      defaultMessage="Step2"
                      description="Step2"
                    />
                  ) : (
                    <FormattedMessage 
                      id="lessehw2.text"
                      defaultMessage="Step2"
                      description="Step2"
                    />
                  )}</p>
                  {/* <p className="mb-0 fw500 cblack">Cras ut tortor vel quam finibus viverra. Praesent mattis mollis dui, quis faucibus magna scelerisque in. Morbi at erat aliquet, </p> */}
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-4">
              <div className="position-relative mb-5 mt-5 hiw-outer-div">
                <div className="d-flex justify-content-center">
                  <div className="hiw-per-top-div">
                    <p className="mb-0 fw700 cwhite fs30">3</p>
                  </div>
                </div>
                <div className="hiw-per-bot-div">
                  <p className="mb-3 fw700 cblack text-uppercase">{me && me.role.id == 3 ? (
                    <FormattedMessage 
                      id="lessorhw3.text"
                      defaultMessage="Step3"
                      description="Step3"
                    />
                  ) : (
                    <FormattedMessage 
                      id="lessehw3.text"
                      defaultMessage="Step3"
                      description="Step3"
                    />
                  )}</p>
                  {/* <p className="mb-0 fw500 cblack">Cras ut tortor vel quam finibus viverra. Praesent mattis mollis dui, quis faucibus magna scelerisque in. Morbi at erat aliquet, </p> */}
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-12 mb-5 d-flex justify-content-center">
              {me && me.role.id == 3 ? (
                <a href="/step1-lessor" className="btn btn-orang-def">
                  Get Started
                </a>
              ) : (
                <a href="/leases" className="btn btn-orang-def">
                  Get Started
                </a>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Partner;
