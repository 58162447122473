const firebaseConfig = {
    apiKey: "AIzaSyDgAZD8vLUDZ9WhI_BasariQvLHTlWVZ-s",
    authDomain: "renter-eaf0f.firebaseapp.com",
    databaseURL: "https://renter-eaf0f-default-rtdb.firebaseio.com",
    projectId: "renter-eaf0f",
    storageBucket: "renter-eaf0f.appspot.com",
    messagingSenderId: "109409391302",
    appId: "1:109409391302:web:7d4df0aba331368f9abdc7",
    measurementId: "G-1NNVG1G3V1"
};
  
export default firebaseConfig