import React from "react";
import {FormattedMessage} from 'react-intl';


const ErrorPayment = () =>{
    const onClickHome = () => {
        window.location.href = "/";
      }
    
    const onClickNoti = () => {
    window.location.href = "/notification";
    }

    return (
        <div>
          <div className="container-fluid leases-payment-wrapper">
            <div className="container main-wrap">
              <div className="row">
                <div className="col-12">
    
                  <div className="d-flex justify-content-center py-5">
                  <svg xmlns="http://www.w3.org/2000/svg" width="158" height="158" viewBox="0 0 24 24" fill="none" stroke="#ff2121" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><circle cx="12" cy="12" r="10"></circle><line x1="15" y1="9" x2="9" y2="15"></line><line x1="9" y1="9" x2="15" y2="15"></line></svg>
                  </div>
    
                  <h3 className="fw700 fs30 text-center cred11 mb-4">
                    <FormattedMessage 
                      id="paymenterror.text"
                      defaultMessage="Payment Error!"
                      description="Payment Error!"
                    />
                  </h3>
                  <p className="fs16 px-2 text-center cblack">
                    <FormattedMessage 
                      id="unsucpay.text"
                      defaultMessage="The payment could not be processed. Please try again or contact your bank."
                      description="The payment could not be processed. Please try again or contact your bank."
                    />
                  </p>
                  
    
                  <button className="btn btn-payment w-100 mt-3" onClick={onClickHome}>
                    <FormattedMessage 
                      id="gotohome.text"
                      defaultMessage="Go to Home"
                      description="Go to Home"
                    />
                  </button>
                  <button className="btn btn-back w-100 mt-3 mb-4" onClick={onClickNoti}>
                    <FormattedMessage 
                      id="checknoti.text"
                      defaultMessage="Check Notifications"
                      description="Check Notifications"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )

}

export default ErrorPayment;