import React, { useState, useEffect, useContext, useRef } from "react";
import {FormattedMessage} from 'react-intl';
import { RouteContext } from "../../../contexts/route.context";
import defaultImage from "../../../assets/images/profpic.svg"


const LessorEarnings = () => {
  const { getMe, me, getTotalEarnings, totalEarnings, getTotalEarningsperYear, totalEarningsperYear, yearChangeLoading,
    setYearChangeLoading, } = useContext(RouteContext);
  const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
  const [showAllEarnings, setShowAllEarnings] = useState(false);
  const [asOf, setAsOf] = useState();
  const [perYear, setPerYear] = useState("2022");
  
  

  useEffect(() => {
    setYearChangeLoading(true);
    getMe();
    getTotalEarnings();
    getTotalEarningsperYear(perYear);
  }, []);


  useEffect(() => {
    if(totalEarnings) {
      const d = new Date(totalEarnings.date_end);
      let name = month[d.getMonth()];
      setAsOf(name);
    }
  }, [totalEarnings]);


  const onChangePerYear = (e) => {
    let val = e.target.value;
    setYearChangeLoading(true);
    setPerYear(val);
    getTotalEarningsperYear(val);
  }

  return (
    <div>
      <div className="my-profile-div">
        <div className="d-flex justify-content-between mb-3">
          <div className="position-relative">
            <div className="myprof-img-div">
              <img alt="" src={me && me.avatar ? me.avatar : defaultImage} className="imgenclose"/>
            </div>
            
            {/* <label htmlFor="photo-btn" className="btn btn-change-photo">
              <svg width="18" height="18" viewBox="0 0 20 20" fill="none" style={{position:"relative", top:"-2px"}} xmlns="http://www.w3.org/2000/svg">
                <path d="M2.5 7.50016C2.5 7.05814 2.67559 6.63421 2.98816 6.32165C3.30072 6.00909 3.72464 5.8335 4.16667 5.8335H4.94167C5.21599 5.83354 5.48608 5.76587 5.72797 5.63649C5.96987 5.50711 6.17609 5.32002 6.32833 5.09183L7.005 4.07516C7.15725 3.84697 7.36347 3.65988 7.60536 3.5305C7.84726 3.40113 8.11735 3.33346 8.39167 3.3335H11.6083C11.8827 3.33346 12.1527 3.40113 12.3946 3.5305C12.6365 3.65988 12.8428 3.84697 12.995 4.07516L13.6717 5.09183C13.8239 5.32002 14.0301 5.50711 14.272 5.63649C14.5139 5.76587 14.784 5.83354 15.0583 5.8335H15.8333C16.2754 5.8335 16.6993 6.00909 17.0118 6.32165C17.3244 6.63421 17.5 7.05814 17.5 7.50016V15.0002C17.5 15.4422 17.3244 15.8661 17.0118 16.1787C16.6993 16.4912 16.2754 16.6668 15.8333 16.6668H4.16667C3.72464 16.6668 3.30072 16.4912 2.98816 16.1787C2.67559 15.8661 2.5 15.4422 2.5 15.0002V7.50016Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M12.5 10.8335C12.5 11.4965 12.2366 12.1324 11.7678 12.6013C11.2989 13.0701 10.663 13.3335 10 13.3335C9.33696 13.3335 8.70107 13.0701 8.23223 12.6013C7.76339 12.1324 7.5 11.4965 7.5 10.8335C7.5 10.1705 7.76339 9.53457 8.23223 9.06573C8.70107 8.59689 9.33696 8.3335 10 8.3335C10.663 8.3335 11.2989 8.59689 11.7678 9.06573C12.2366 9.53457 12.5 10.1705 12.5 10.8335V10.8335Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </label>
            <input type="file" name="avatar" accept="image/*" id="photo-btn" onChange={onChangeAvatar} hidden/> */}
          </div>
          {/* <button className="btn btn-save-changes">Edit Profile</button> */}
        </div>

        <p className="mb-2 fw700 fs18 cblue">{me && me.name}</p>
        <p className="mb-3 fs14 cgray">
          <svg width="18" height="21" style={{marginRight:"5px", marginTop:"-2px",marginLeft:"5px",}} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M3.06022 2.85984C4.6356 1.28446 6.77228 0.399414 9.00021 0.399414C11.2281 0.399414 13.3648 1.28446 14.9402 2.85984C16.5156 4.43523 17.4006 6.57191 17.4006 8.79984C17.4006 11.0278 16.5156 13.1645 14.9402 14.7398L9.00021 20.6798L3.06022 14.7398C2.28011 13.9598 1.6613 13.0338 1.2391 12.0146C0.81691 10.9954 0.599609 9.90302 0.599609 8.79984C0.599609 7.69667 0.81691 6.6043 1.2391 5.5851C1.6613 4.56591 2.28011 3.63987 3.06022 2.85984ZM9.00021 11.1998C9.63673 11.1998 10.2472 10.947 10.6973 10.4969C11.1474 10.0468 11.4002 9.43636 11.4002 8.79984C11.4002 8.16332 11.1474 7.55287 10.6973 7.10279C10.2472 6.6527 9.63673 6.39984 9.00021 6.39984C8.3637 6.39984 7.75325 6.6527 7.30316 7.10279C6.85307 7.55287 6.60021 8.16332 6.60021 8.79984C6.60021 9.43636 6.85307 10.0468 7.30316 10.4969C7.75325 10.947 8.3637 11.1998 9.00021 11.1998Z" fill="#AAAAAA"/>
          </svg>
          {me && me.address}
        </p>
        <div className="div-2px mb-3" />


        <div className="earnings-div">
          {showAllEarnings ? (
            <div>

              <div className="prof-per-div">
                <label className="cgray fs14 me-5 ms-5">
                  <FormattedMessage 
                    id="chooseayear.text"
                    defaultMessage="Choose a year"
                    description="Choose a year"
                  />
                </label>
                <div className="right">
                  <select className="form-select" name="gender" defaultValue={perYear} onChange={onChangePerYear}>
                    <option value="2021">2021</option>
                    <option value="2022">2022</option>
                    <option value="2023">2023</option>
                    <option value="2024">2024</option>
                  </select>
                  {/* <span className="setnow">Set Now</span> */}
                </div>
              </div>

              {!yearChangeLoading ? (
                totalEarningsperYear.map((ear) => {

                  return (
                    <div>
                      <p className="fs14 cblack2 mb-3"><FormattedMessage 
                        id="earningsfor.text"
                        defaultMessage="Earnings for"
                        description="Earnings for"
                      /> {ear.month}</p>
                      <div className="earnings-whole-div">
                        <p className="fw700 fs22 cblack2 d-flex justify-content-between mb-2">
                          <FormattedMessage 
                            id="property.text"
                            defaultMessage="Property"
                            description="Property"
                          />
                          <span>
                            <FormattedMessage 
                              id="revenue.text"
                              defaultMessage="Revenue"
                              description="Revenue"
                            />
                          </span>
                        </p>


                        {ear.post_reports.map((pr) => (
                          <p className="fw400 fs14 cblack2 d-flex justify-content-between mb-2">
                            {pr.title}
                            <span className="fw700">SAR {pr.total}</span>
                          </p>
                        ))}

                        <div className="divider-dashed mb-3 mt-3"></div>

                        <p className="fw700 fs22 cblack2 d-flex justify-content-between mb-2">
                          <FormattedMessage 
                            id="total.text"
                            defaultMessage="Total"
                            description="Total"
                          />
                          <span>SAR {ear.total}</span>
                        </p>
                      </div>
                    </div>
                  )
                })
              ) : (
                <div className="loader"></div>
              )}
              
            </div>
          ) : (
            <div>
              <p className="cblack2 mb-3 fs14 d-flex justify-content-between">
                <FormattedMessage 
                  id="mytotalearning.text"
                  defaultMessage="My Total Earnings"
                  description="My Total Earnings"
                />:
                <span className="cred cur-pointer" onClick={() => setShowAllEarnings(true)}>See all</span>
              </p>

              <div className="earnings-inner-div text-center">
                <p className="fw700 fs20 cblack mb-3"><FormattedMessage 
                  id="earningsasof.text"
                  defaultMessage="Earnings as of"
                  description="Earnings as of"
                /> {asOf}</p>
                <div className="earning-amount-div">
                  SAR {totalEarnings && totalEarnings.total}
                </div>
                <p className="cgray fs16 mb-0 ">
                  <FormattedMessage 
                    id="numleasedproper.text"
                    defaultMessage="Number of leased properties"
                    description="Number of leased properties"
                  />:
                  <span className="cblack"> {totalEarnings && totalEarnings.count}</span>
                </p>
              </div>
            </div>
          )}
          
        </div>   
      </div>
    </div>
  )
}

export default LessorEarnings;
