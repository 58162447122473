import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import {IntlProvider} from "react-intl";
import Cookies from 'universal-cookie';
import {BrowserRouter} from "react-router-dom";
import RouteProvider from "./contexts/route.context";
import FirebaseProvider from "./contexts/firebase.context";
import messages_ar from "./util/ar.json";
import messages_en from "./util/en.json";

const cookies = new Cookies();
const messages = {
  'ar': messages_ar,
  'en': messages_en
};
let language = cookies.get("lang") ? cookies.get("lang") : "en";

ReactDOM.render(
  <IntlProvider locale={language} messages={messages[language]}>
    <BrowserRouter>
      <FirebaseProvider>
        <RouteProvider>
          <App />
        </RouteProvider>
      </FirebaseProvider>
    </BrowserRouter>
  </IntlProvider>,
  document.getElementById('root')
);

if(language !== "en") {
  document.getElementsByTagName('html')[0].setAttribute("dir", "rtl");
  document.getElementsByTagName('html')[0].setAttribute("lang", "ar");
}