import React, { useState, useEffect, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Cookies from 'universal-cookie';
import { RouteContext } from "../../contexts/route.context";


import LoginLessee from "../lessee/login";
import VerificationLessee from "../lessee/verification";
import Step1 from "../lessee/registration/step1";
import Step2 from "../lessee/registration/step2";
import Step3 from "../lessee/registration/step3";
import Home from "../home";
import Notification from "../notification";
import ContactusHome from "../contactus";
import Leases from "../lessee/leases";
import LeasesSearch from "../lessee/leasessearch";
import AllCategories from "../lessee/allcategories";
import LeaseSingle from "../lessee/leasesingle";
import LeaseForm from "../lessee/leaseform";
import LeasePayment from "../lessee/leasepayment";
import LeaseBilling from "../lessee/leasebilling";
import MyLeases from "../lessee/myleases";
import MyLeaseSingleActive from "../lessee/myleasesingleactive";
import MyLeaseSingleHistory from "../lessee/myleasesinglehistory";
import MyLeaseSingle from "../lessee/myleasesingle";
import ChatLease from "../lessee/chatlease";
import LessorProfile from "../lessee/lessorprofile";
import LesseeSettings from "../lessee/settings/lesseesettings";
import Terms from "../settings/terms";
import AboutUs from "../settings/aboutus";
import Partner from "../partner";
import Support from "../support";
import Cancellation from "../settings/cancellation";
import Privacy from "../settings/privacy";


import LoginLessor from "../lessor/loginlessor";
import VerificationLessor from "../lessor/verificationlessor";
import Step1Lessor from "../lessor/registration/step1lessor";
import Step2Lessor from "../lessor/registration/step2lessor";
import Step3Lessor from "../lessor/registration/step3lessor";
import Profile from "../lessor/profile";
import LessorSinglePost from "../lessor/lessorsinglepost";
import EditPost from "../lessor/editpost";
import AddPost from "../lessor/addpost";
import LessorPayment from "../lessor/lessorpayment";
import LessorPaymentSuccess from "../lessor/lessorpaymentsucess";
import LessorBilling from "../lessor/lessorbilling";
import LessorLeases from "../lessor/lessorleases";
import LessorLeaseSingle from "../lessor/lessorleasesingle";
import LessorChat from "../lessor/lessorchat";
import LessorSettings from "../lessor/settings/lessorsettings";
import moment from "moment";
import ErrorPayment from "../errorpayment";
import LessorLeaseSingleActive from "../lessor/lessorleasesingleactive";
import LessorLeaseSingleHistory from "../lessor/lessorleasesinglehistory";



const Routing = () => {
  const { baseURL, } = useContext(RouteContext);


    return (
      <Switch>
        <Route exact path="/login">
          <LoginLessee/>
        </Route>
        <Route exact path="/verification">
          <VerificationLessee/>
        </Route>
        <Route exact path="/step1">
          <Step1/>
        </Route>
        <Route exact path="/step2">
          <Step2/>
        </Route>
        <Route exact path="/step3">
          <Step3/>
        </Route>
        
        
        <Route exact path="/login-lessor">
          <LoginLessor/>
        </Route>
        <Route exact path="/verification-lessor">
          <VerificationLessor/>
        </Route>
        <Route exact path="/step1-lessor">
          <Step1Lessor/>
        </Route>
        <Route exact path="/step2-lessor">
          <Step2Lessor/>
        </Route>
        <Route exact path="/step3-lessor">
          <Step3Lessor/>
        </Route>
        <Route exact path="/leases">
          <Leases/>
        </Route>
        <Route exact path="/lease/:id">
          <LeaseSingle/>
        </Route>
        <Route exact path="/partner">
          <Partner/>
        </Route>
        <Route exact path="/support">
          <Support/>
        </Route>
        <Route exact path="/cancellation-policy">
          <Cancellation/>
        </Route>
        <Route exact path="/privacy">
          <Privacy/>
        </Route>
        <PrivateRoute exact path="/lease-payment">
          <LeasePayment/>
        </PrivateRoute>
        <PrivateRoute exact path="/my-leases">
          <MyLeases/>
        </PrivateRoute>
        
        <PrivateRoute exact path="/my-lease/active/:id">
          <MyLeaseSingleActive/>
        </PrivateRoute>

        <PrivateRoute exact path="/my-lease/history/:id">
          <MyLeaseSingleHistory/>
        </PrivateRoute>

        <PrivateRoute exact path="/lessor-profile">
          <LessorProfile/>
        </PrivateRoute>
        <PrivateRoute exact path="/lease-chat">
          <ChatLease/>
        </PrivateRoute>
        <PrivateRoute exact path="/lessee-settings">
          <LesseeSettings/>
        </PrivateRoute>
        <Route exact path="/terms">
          <Terms/>
        </Route>
        <Route exact path="/about">
          <AboutUs/>
        </Route>


        <Route exact path="/">
          <Home/>
        </Route>

        
        <PrivateRoute exact path="/notification">
          <Notification/>
        </PrivateRoute>
        <PrivateRoute exact path="/contact">
          <ContactusHome/>
        </PrivateRoute>
        
        <PrivateRoute exact path="/leases-search">
          <LeasesSearch/>
        </PrivateRoute>
        <PrivateRoute exact path="/allcategories">
          <AllCategories/>
        </PrivateRoute>
        {/* <PrivateRoute exact path="/lease-single">
          <LeaseSingle/>
        </PrivateRoute> */}
        {/* <PrivateRoute exact path="/lease-form">
          <LeaseForm/>
        </PrivateRoute> */}
        {/* <PrivateRoute exact path="/lease-billing">
          <LeaseBilling/>
        </PrivateRoute> */}
        {/* <PrivateRoute exact path="/my-lease-single">
          <MyLeaseSingle/>
        </PrivateRoute> */}
        

        
        {/* lessor view */}
        <PrivateRoute exact path="/lessor-leases">
          <LessorLeases/>
        </PrivateRoute>
        <PrivateRoute exact path="/my-lessor-profile">
          <Profile/>
        </PrivateRoute>
        <PrivateRoute exact path="/add-post">
          <AddPost/>
        </PrivateRoute>
        <PrivateRoute exact path="/edit-post">
          <EditPost/>
        </PrivateRoute>
        <PrivateRoute exact path="/lessor-payment">
          <LessorPayment/>
        </PrivateRoute>
        <PrivateRoute exact path="/payment">
          <LessorPaymentSuccess/>
        </PrivateRoute>
        <PrivateRoute exact path="/lessor-lease-single">
          <LessorLeaseSingle/>
        </PrivateRoute>

        <PrivateRoute exact path="/lessor-lease-single/active/:id">
          <LessorLeaseSingleActive/>
        </PrivateRoute>

        <PrivateRoute exact path="/lessor-lease-single/history/:id">
          <LessorLeaseSingleHistory/>
        </PrivateRoute>

        <PrivateRoute exact path="/lessor-settings">
          <LessorSettings/>
        </PrivateRoute>
        <PrivateRoute exact path="/lessor-chat">
          <LessorChat/>
        </PrivateRoute>


        {/* <PrivateRoute exact path="/lessor-single-post">
          <LessorSinglePost/>
        </PrivateRoute> */}
        {/* <PrivateRoute exact path="/lessor-billing">
          <LessorBilling/>
        </PrivateRoute> */}
        
        <PrivateRoute exact path="/error">
          <ErrorPayment/>
        </PrivateRoute>

        
      </Switch>
    )
}

// user needs to login to access routes
function PrivateRoute({children, ...rest}) {
  const cookies = new Cookies();

  let userCookie = cookies.get('userTkn');

  return (
    <Route
      {...rest}
      render={({ location }) => 
        userCookie ? (
          children
        ) : (
          <Redirect 
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}


// if user is already login cannot access login page
// function LoginRoute({children, ...rest}) {
//   const cookies = new Cookies();
//   let userCookie = cookies.get('userTkn');

//   return (
//     <Route
//       {...rest}
//       render={({ location }) => 
//         userCookie ? (
//           <Redirect 
//             to={{
//               pathname: "/",
//               state: { from: location },
//             }}
//           />
//         ) : (
//           children
//         )
//       }
//     />
//   );
// }

export default Routing;
