import React, { useState, useEffect, useContext, useRef } from "react";
import axios from "axios";
import Swal from 'sweetalert2';
import {FormattedMessage, useIntl} from 'react-intl';
import { RouteContext } from "../../../contexts/route.context";


const Contactus = () => {
  const intl = useIntl();
  const { baseURL, apiHeaders, getMe, me, getAllSettings,
    allSettings } = useContext(RouteContext);

  useEffect(() => {
    getMe();
    getAllSettings();
  }, []);

  const onSubmitInquiry = async(e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    let formdataObj = Object.fromEntries(formData.entries());
    // formdataObj.user_id = me.id;

    let result = await axios({
      url: baseURL,
      headers: apiHeaders,
      method: "post",
      data: {
        query: `
        mutation {
          createInquiry(input: {message: "${formdataObj.message}", phone: "${formdataObj.phone}", subject: "${formdataObj.subject}"}){
            id
            message
            status
          }
        }
        `
      }
    });


    if(result.data.data) {
      Swal.fire({
        title: 'Added an Inquiry',
        text: "",
        icon: 'success',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });
    }
    else {
      Swal.fire({
        title: 'Create Inquiry Failed',
        text: result.data.errors[0].message,
        icon: 'warning',
        confirmButtonColor: '#ff9035',
      }).then((e) => {
        window.location.reload();
      });

    }
  }

  return (
    <div>
      <div className="my-profile-div">
        <div className="row">
          <div className="col-12">
            <p className="fw700 cblue fs20 mb-2">
              <FormattedMessage 
                id="contactus.text"
                defaultMessage="Contact Us"
                description="Contact Us"
              />
            </p>
            <p className="cblack fs14 mb-3">
              <FormattedMessage 
                id="contactusdesc.text"
                defaultMessage="Contact Us desc"
                description="Contact Us desc"
              />
            </p>

            <div className="div-2px mb-3" />

            <p className="fw700 cblue fs16 mb-3">
              <FormattedMessage 
                id="forinqleavemsg.text"
                defaultMessage="For inquiries, please leave a message here"
                description="For inquiries, please leave a message here"
              />
            </p>

            <div className="contact-us-div">
              <form className="verification-div text-center" onSubmit={onSubmitInquiry}>
                <input type="text" className="form-control contactus-form" name="subject" placeholder={intl.formatMessage({
                  id: 'subject.text',
                  defaultMessage: 'Subject',
                  description: 'Subject',
                })} />
                <input type="text" className="form-control contactus-form" name="phone" placeholder={intl.formatMessage({
                  id: 'phone.text',
                  defaultMessage: 'Phone',
                  description: 'Phone',
                })} />
                <textarea className="form-control contactus-form textarea" placeholder={intl.formatMessage({
                  id: 'writereviewoption.text',
                  defaultMessage: 'Write a review (optional)',
                  description: 'Write a review (optional)',
                })} rows="3" name="message" required></textarea>
                <button type="submit" className="btn btn-submit-contactus">
                  <FormattedMessage 
                    id="submit.text"
                    defaultMessage="Submit"
                    description="Submit"
                  />
                </button>
              </form>


              <p className="fw700 cblack fs16 mb-3">
                <FormattedMessage 
                  id="followuson.text"
                  defaultMessage="Follow us on"
                  description="Follow us on"
                />:
              </p>
              <div className="d-flex contactus-social-icons mb-3">
                {allSettings && (
                  <div className="d-flex float-sm-end">
                    <a href={allSettings.social_link_3} target="_blank" rel="noreferrer" className="me-3">
                      <svg xmlns="http://www.w3.org/2000/svg" height="18" width="18" viewBox="0 0 448 512"><path d="M194.4 211.7a53.3 53.3 0 1 0 59.3 88.7 53.3 53.3 0 1 0 -59.3-88.7zm142.3-68.4c-5.2-5.2-11.5-9.3-18.4-12c-18.1-7.1-57.6-6.8-83.1-6.5c-4.1 0-7.9 .1-11.2 .1c-3.3 0-7.2 0-11.4-.1c-25.5-.3-64.8-.7-82.9 6.5c-6.9 2.7-13.1 6.8-18.4 12s-9.3 11.5-12 18.4c-7.1 18.1-6.7 57.7-6.5 83.2c0 4.1 .1 7.9 .1 11.1s0 7-.1 11.1c-.2 25.5-.6 65.1 6.5 83.2c2.7 6.9 6.8 13.1 12 18.4s11.5 9.3 18.4 12c18.1 7.1 57.6 6.8 83.1 6.5c4.1 0 7.9-.1 11.2-.1c3.3 0 7.2 0 11.4 .1c25.5 .3 64.8 .7 82.9-6.5c6.9-2.7 13.1-6.8 18.4-12s9.3-11.5 12-18.4c7.2-18 6.8-57.4 6.5-83c0-4.2-.1-8.1-.1-11.4s0-7.1 .1-11.4c.3-25.5 .7-64.9-6.5-83l0 0c-2.7-6.9-6.8-13.1-12-18.4zm-67.1 44.5A82 82 0 1 1 178.4 324.2a82 82 0 1 1 91.1-136.4zm29.2-1.3c-3.1-2.1-5.6-5.1-7.1-8.6s-1.8-7.3-1.1-11.1s2.6-7.1 5.2-9.8s6.1-4.5 9.8-5.2s7.6-.4 11.1 1.1s6.5 3.9 8.6 7s3.2 6.8 3.2 10.6c0 2.5-.5 5-1.4 7.3s-2.4 4.4-4.1 6.2s-3.9 3.2-6.2 4.2s-4.8 1.5-7.3 1.5l0 0c-3.8 0-7.5-1.1-10.6-3.2zM448 96c0-35.3-28.7-64-64-64H64C28.7 32 0 60.7 0 96V416c0 35.3 28.7 64 64 64H384c35.3 0 64-28.7 64-64V96zM357 389c-18.7 18.7-41.4 24.6-67 25.9c-26.4 1.5-105.6 1.5-132 0c-25.6-1.3-48.3-7.2-67-25.9s-24.6-41.4-25.8-67c-1.5-26.4-1.5-105.6 0-132c1.3-25.6 7.1-48.3 25.8-67s41.5-24.6 67-25.8c26.4-1.5 105.6-1.5 132 0c25.6 1.3 48.3 7.1 67 25.8s24.6 41.4 25.8 67c1.5 26.3 1.5 105.4 0 131.9c-1.3 25.6-7.1 48.3-25.8 67z"/></svg>
                    </a>
                    <a href={allSettings.social_link_4} target="_blank" rel="noreferrer" className="me-3">
                      <img alt="" src="assets/images/linkedin.svg" />
                    </a>
                    <a href={allSettings.social_link_2} target="_blank" rel="noreferrer" className="me-2">
                      <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z"/></svg>
                    </a>
                  </div>
                )}
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
  )
}

export default Contactus;
