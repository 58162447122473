import {React, useState, useEffect} from 'react';
import {FormattedMessage, useIntl} from 'react-intl';
import moment from 'moment';
import styles from './mycalendar.module.css';

const MyCalendar = ({sendSelectedDates, index, term, myValue, postDates0, postDates1, postDates2}) => {
    const intl = useIntl();
    const date = new Date();
    let dday = date.getDate();

    const months = moment.months();

    const thismonth = moment().month();
    const thisyear = date.getFullYear()
    const [currentYear, setYear] = useState(thisyear);
    const [currentMonth, setCurrentMonth] = useState(moment().month());

    const [testDates, setTestDates] = useState([]);
    const [from, setFrom] = useState("");
    const [to, setTo] = useState("");

    useEffect(()=>{
      if(myValue){
        setFrom(myValue[0]);
        setTo(myValue[myValue.length-1]);
        setTestDates(myValue);
      }
    }, [myValue])

    // useEffect(()=>{
      // if(id === localStorage.getItem("testDates"[1])){
        // setTestDates(localStorage.getItem("testDates"));
      // }
      // if(id === "calendar0"){
      //   if(localStorage.getItem("testDates") && localStorage.getItem("testDates")[1] === "0"){
      //     setTestDates(localStorage.getItem("testDates"));
      //   }
      // }
    // }, [])

    const getDateRange = (firstDate, lastDate) => {
      if (moment(firstDate, "YYYY-MM-DD").isSame(moment(lastDate, "YYYY-MM-DD"), "day"))
        return [lastDate];
      let date = firstDate;
      const dates = [date];
      do {
        date = moment(date,"YYYY-MM-DD").add(1, "day");
        dates.push(date.format("YYYY-MM-DD"));
      } while (moment(date).isBefore(lastDate));
      return dates;
    };

    const checkDateWithin = (value, from, to) => {
      console.log(from, to, testDates);
      let datestart = from;
      do {
        datestart = moment(datestart,"YYYY-MM-DD").add(1, "day");
        if(datestart === value){
         if(testDates.includes(datestart)){
            return true;
          }
          else{
            return false;
          }
         }
      } while (moment(datestart).isBefore(to));
    }

    const onSelectDates = (value) => {
      if(value === testDates[0] || value === testDates[testDates.length - 1]) {
        //check if "From" or "To" have been set]
          localStorage.setItem("testDates", [[], index, term])
          setTestDates([]);
          setFrom("");
          setTo("");
          sendSelectedDates([], index, term);
      }
      else if(from === ""){
        //check if "From" has been set
          setFrom(value);
          setTestDates([...testDates, value]);
          sendSelectedDates(value, index, term);
          localStorage.setItem("testDates", [value, index, term])
      }
      else if(from !== "" && to ===""){
        //check if "To" has been set
        if(moment(value).isBefore(from)){
          //set date range "From" - "To"
          let x = getDateRange(value, from);
          console.log(x);
          setTestDates(x);
          let sub = from;
          setFrom(value);
          setTo(sub);
          sendSelectedDates(x, index, term);
          localStorage.setItem("testDates", [x, index, term])
        }
        else{
          //set date range "To" - "From"
          setTo(value)
          let x = getDateRange(from, value);
          console.log(x);
          setTestDates(x);
          sendSelectedDates(x, index, term);
          localStorage.setItem("testDates", [x, index, term])
        }
      }
      else if(testDates.includes(value)){
        //Remove specific date from the selected date range
        if(term !== "monthly"){
          let x = testDates.filter(testDates => testDates !== value)
          setTestDates(oldFilter=>{
            return oldFilter.filter(testDates => testDates !== value)
          })
          sendSelectedDates(x, index, term)
          localStorage.setItem("testDates", [x, index, term])
        }
        
      }
      else if(moment(to).isBefore(value) && from !== ""){
        //set new "To" to extend the range
          setTo(value)
          let x = getDateRange(from, value);
          setTestDates(x);
          sendSelectedDates(x, index, term);
          localStorage.setItem("testDates", [x, index, term])    
      }
      else if(!checkDateWithin(value, from, to)){
        //add back the recently removed date
        setTestDates([value, ...testDates]);
          sendSelectedDates([value, ...testDates], index, term);
          localStorage.setItem("testDates", [[value, ...testDates], index, term])
      }
    }

    const isExtraDays = (week, date) => {
        if (week === 0 && date > 10) {
          return true;
        } else if (week === 5 && date < 10) {
          return true;
        } else if (week === 4 && date < 10) {
          return true;
        } else {
          return false;
        }
      };

      const convertMonthRange = (val) => {
        switch(val){
          case 0:
            return "01"
          case 1:
            return "02"
          case 2:
            return "03"
          case 3:
            return "04"
          case 4:
            return "05"
          case 5:
            return "06"
          case 6:
            return "07"
          case 7:
            return "08"
          case 8:
            return "09"
          case 9:
            return "10"
          case 10:
            return "11"
          case 11:
            return "12"
        }
      }

      const getDate = (month) => {
        var calendar = [];
    
        const startDate = moment([currentYear, month])
          .clone()
          .startOf("month")
          .startOf("week");
    
        const endDate = moment([currentYear, month]).clone().endOf("month");
    
        const day = startDate.clone().subtract(1, "day");
    
        // looping a month by a week
        while (day.isBefore(endDate, "day")) {
          calendar.push(
            Array(7)
              .fill(0)
              .map(() => day.add(1, "day").clone().format("DD"))
          );
        }
        if(index === 0 && postDates0){
          if (calendar.length > 0) {
            return calendar.map((week, index) => (
              <tr key={index} className={`${styles.calendarContainer}`}>
                {week.map((day, i) => (
                  <td key={day} className={`${styles.calendarCol} ${postDates0.includes(currentYear.toString() + "-" + convertMonthRange(currentMonth) + "-" + day) && !isExtraDays(index, day) ? styles.selectedDate:""}`}>
                    {/* <p className={`${thisyear > currentYear ? styles.extraDays : styles.availDays}`}> */}
                    <div className={`${styles.myDates}`}>
                      <a
                      onClick={()=>{
                          onSelectDates((currentYear + "-" + convertMonthRange(currentMonth) + "-" + day));
                        }
                      }
                      className={`${styles.availDays} ${
                        thisyear > currentYear || 
                      (thisyear === currentYear && thismonth+1 > currentMonth+1) || 
                      (thismonth === currentMonth && dday+1 > day) ? styles.otherExtraDays : null}
                      `}
                      >
                        {isExtraDays(index, day) ? (
                          null
                        ) : (
                          <span>{day.toString().replace(/^0+/, "")}</span>
                        )}
                      </a>
                    </div>
                  </td>
                ))}
              </tr>
            ));
          }
        }
        else if(index === 1 && postDates1){
          if (calendar.length > 0) {
            return calendar.map((week, index) => (
              <tr key={index} className={`${styles.calendarContainer}`}>
                {week.map((day, i) => (
                  <td key={day} className={`${styles.calendarCol} ${postDates1.includes(currentYear.toString() + "-" + convertMonthRange(currentMonth) + "-" + day) && !isExtraDays(index, day) ? styles.selectedDate:""}`}>
                    {/* <p className={`${thisyear > currentYear ? styles.extraDays : styles.availDays}`}> */}
                    <div className={`${styles.myDates}`}>
                      <a
                      onClick={()=>{
                          onSelectDates((currentYear + "-" + convertMonthRange(currentMonth) + "-" + day));
                        }
                      }
                      className={`${styles.availDays} ${
                        thisyear > currentYear || 
                      (thisyear === currentYear && thismonth+1 > currentMonth+1) || 
                      (thismonth === currentMonth && dday+1 > day) ? styles.otherExtraDays : null}
                      `}
                      >
                        {isExtraDays(index, day) ? (
                          null
                        ) : (
                          <span>{day.toString().replace(/^0+/, "")}</span>
                        )}
                      </a>
                    </div>
                  </td>
                ))}
              </tr>
            ));
          }
        }
        else if(index === 2 && postDates2){
          if (calendar.length > 0) {
            return calendar.map((week, index) => (
              <tr key={index} className={`${styles.calendarContainer}`}>
                {week.map((day, i) => (
                  <td key={day} className={`${styles.calendarCol} ${postDates2.includes(currentYear.toString() + "-" + convertMonthRange(currentMonth) + "-" + day) && !isExtraDays(index, day) ? styles.selectedDate:""}`}>
                    {/* <p className={`${thisyear > currentYear ? styles.extraDays : styles.availDays}`}> */}
                    <div className={`${styles.myDates}`}>
                      <a
                      onClick={()=>{
                          onSelectDates((currentYear + "-" + convertMonthRange(currentMonth) + "-" + day));
                        }
                      }
                      className={`${styles.availDays} ${
                        thisyear > currentYear || 
                      (thisyear === currentYear && thismonth+1 > currentMonth+1) || 
                      (thismonth === currentMonth && dday+1 > day) ? styles.otherExtraDays : null}
                      `}
                      >
                        {isExtraDays(index, day) ? (
                          null
                        ) : (
                          <span>{day.toString().replace(/^0+/, "")}</span>
                        )}
                      </a>
                    </div>
                  </td>
                ))}
              </tr>
            ));
          }
        }
        else{
          if (calendar.length > 0) {
            return calendar.map((week, index) => (
              <tr key={index} className={`${styles.calendarContainer}`}>
                {week.map((day, i) => (
                  <td key={day} className={`${styles.calendarCol} ${testDates.includes(currentYear.toString() + "-" + convertMonthRange(currentMonth) + "-" + day) && !isExtraDays(index, day) ? styles.selectedDate:""}`}>
                    {/* <p className={`${thisyear > currentYear ? styles.extraDays : styles.availDays}`}> */}
                    <div className={`${styles.myDates}`}>
                      <a
                      onClick={()=>{
                          onSelectDates((currentYear + "-" + convertMonthRange(currentMonth) + "-" + day));
                        }
                      }
                      className={`${styles.availDays} ${
                        thisyear > currentYear || 
                      (thisyear === currentYear && thismonth+1 > currentMonth+1) || 
                      (thismonth === currentMonth && dday+1 > day) ? styles.otherExtraDays : null}
                      `}
                      >
                        {isExtraDays(index, day) ? (
                          null
                        ) : (
                          <span>{day.toString().replace(/^0+/, "")}</span>
                        )}
                      </a>
                    </div>
                  </td>
                ))}
              </tr>
            ));
          }
        }
    
        
      };

    const nextMonth = () => {
        if(currentMonth !== 11){
            setCurrentMonth(currentMonth+1);
        }
        else{
            setCurrentMonth(0);
            setYear(currentYear+1);
        }
    }

    const prevMonth = () => {
        if(currentMonth !== 0) {
            setCurrentMonth(currentMonth-1);
        }
        else{
            setCurrentMonth(11);
            setYear(currentYear-1);
        }
    }

    return(
      <>
        <div className={`${styles.calendarcontainer}`}>
          <div className={`${styles.dateAvail}`}>
            <p className="fw600 fs16 cblack2 mt-2">
              <svg width="18" height="18" style={{marginRight:"8px", marginTop:"-3px"}} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M6 0C5.60218 0 5.22064 0.158035 4.93934 0.43934C4.65804 0.720644 4.5 1.10218 4.5 1.5V3H3C2.20435 3 1.44129 3.31607 0.87868 3.87868C0.31607 4.44129 0 5.20435 0 6V21C0 21.7956 0.31607 22.5587 0.87868 23.1213C1.44129 23.6839 2.20435 24 3 24H21C21.7956 24 22.5587 23.6839 23.1213 23.1213C23.6839 22.5587 24 21.7956 24 21V6C24 5.20435 23.6839 4.44129 23.1213 3.87868C22.5587 3.31607 21.7956 3 21 3H19.5V1.5C19.5 1.10218 19.342 0.720644 19.0607 0.43934C18.7794 0.158035 18.3978 0 18 0C17.6022 0 17.2206 0.158035 16.9393 0.43934C16.658 0.720644 16.5 1.10218 16.5 1.5V3H7.5V1.5C7.5 1.10218 7.34196 0.720644 7.06066 0.43934C6.77936 0.158035 6.39782 0 6 0ZM6 7.5C5.60218 7.5 5.22064 7.65804 4.93934 7.93934C4.65804 8.22064 4.5 8.60218 4.5 9C4.5 9.39782 4.65804 9.77936 4.93934 10.0607C5.22064 10.342 5.60218 10.5 6 10.5H18C18.3978 10.5 18.7794 10.342 19.0607 10.0607C19.342 9.77936 19.5 9.39782 19.5 9C19.5 8.60218 19.342 8.22064 19.0607 7.93934C18.7794 7.65804 18.3978 7.5 18 7.5H6Z" fill="#181818"/>
              </svg>
              <FormattedMessage 
                id="dateavail.text"
                defaultMessage="Date Availability"
                description="Date Availability"
              />
            </p>
          </div>
          <div className={`${styles.calendarhead} d-flex justify-content-around`}>
            {/* <p onClick={prevMonth}>Prev</p> */}
            <svg onClick={prevMonth} xmlns="http://www.w3.org/2000/svg" cursor={"pointer"} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M15 18l-6-6 6-6"/></svg>
            <p>{months[currentMonth]}, {currentYear}</p>
            {/* <p onClick={nextMonth}>Next</p> */}
            <svg onClick={nextMonth} xmlns="http://www.w3.org/2000/svg" cursor={"pointer"} width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#000000" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 18l6-6-6-6"/></svg>
          </div>
            <div className={`${styles.tableContainer}`}>
              <table celled="true" padded="true" className={`${styles.calendarContainer}`}>
                <tbody>
                <tr className={`${styles.daysContainer}`}>
                  <th>S</th>
                  <th>M</th>
                  <th>T</th>
                  <th>W</th>
                  <th>T</th>
                  <th>F</th>
                  <th>S</th>
                </tr>
                {getDate(currentMonth)}
                </tbody>
              </table>
            </div>
        </div>
      </>
    )
    
}

export default MyCalendar;